import React from "react";
import logo from './logo.svg';
import './App.css';

import Stories from 'react-insta-stories';

const StoriesTest = ({ item, stories, onAllStoriesEnd }) => (
  <div style={{ width: "100%", height: "100vh", position: "absolute", top: 0, left: 0, zIndex: 100, }}>
  <Stories
      stories={stories ?? [
        {
          duration: 5000,
          content: (props) => (
            <div style={{ background: 'white', padding: 20, width: "100%", height: "100vh" }}>
              <h1 style={{ marginTop: '48px', marginBottom: 0 }}>{item.name}</h1>
              <img src="https://randompicturegenerator.com/img/picture-generator/57e6d1404b57aa14f1dc8460962e33791c3ad6e04e507440762e79d0974ac3_640.jpg" width="100%" />
              <h3 style={{ marginTop: 5 }}>{item.description}</h3>
            </div>
          ),
        },
        {
          url: 'https://randompicturegenerator.com/img/picture-generator/52e3d541435ba814f1dc8460962e33791c3ad6e04e5074417c2e7dd29045c2_640.jpg',
          duration: 2000,
          seeMore: ({ close }) => {
            return (
              <div style={{ backgroundColor: "white", height: "100vh", width: "100%" }} onClick={close}>Hello, click to close this.</div>
            );
          },
        },
        {
          url: 'https://samplelib.com/lib/preview/mp4/sample-5s.mp4',
          duration: 5000,
          type: 'video',
        },
      ]}
      defaultInterval={1500}
      width={"100%"}
      height={"100vh"}
      keyboardNavigation={true}
      loop={false}
      onAllStoriesEnd={onAllStoriesEnd}
    />
  </div>
);

const App = () => {

  const [selectedItem, setSelectedItem] = React.useState();
  const [filters, setFilters] = React.useState(null);

  const items = [
    {
      name: "IWF",
      image: "",
      description: "A mezcal milk-punch that'll get you in the mood",
      tags: ["mezcal", "milk-punch"],
      stories: null,
    },
    {
      name: "Flight 815",
      image: "",
      description: "A numerically perfect Paper Plane imposter. 4 8 15 16 23 42",
      tags: ["whiskey", "gluten-free"],
      stories: null,
    },
    {
      name: "Flowers, Always",
      image: "",
      tags: ["absinthe", "egg-white"],
      stories: null,
    },
    {
      name: "Taylor's Version",
      image: "",
      tags: ["gin", "champagne"],
      stories: null,
    },
    {
      name: "Crying at the Orgy",
      image: "",
      tags: ["tequila"],
      stories: null,
    },
    {
      name: "That Funny Feeling",
      image: "",
      tags: ["scotch", "whiskey"],
      stories: null,
    },
    {
      name: "Death with Benefits",
      image: "",
      tags: ["whiskey"],
      stories: null,
    },
    {
      name: "My Dearest, Angelica",
      image: "",
      tags: ["gin"],
      stories: null,
    },
    {
      name: "The Millennial Pause",
      image: "",
      tags: ["low-abv"],
      stories: null,
    },
    {
      name: "Not Like Other Girls",
      image: "",
      tags: ["vodka", "benedictine"],
      stories: null,
    },
  ]

  return (
    <div className="App">
      {/* <header className="App-header">
      </header> */}

      <h1>HBD Matt</h1>
      <p>a pop-up in NYC</p>

      <h3 style={{ marginBottom: "4px" }}>Filter</h3>
      <div style={{ display: "inline-flex", }}>
      {["all", "whiskey", "absinthe", "tequila", "gin", "vodka", "mezcal"].map((tag) => (
        <div 
          style={{ 
            margin: "4px",
            textDecoration: ((filters && tag === filters) || (!filters && tag === "all")) ? "underline" : "none",
          }}
          onClick={() => {
          if (tag === filters || tag === "all") {
            setFilters(null)
          } else {
            setFilters(tag)
          }
        }}>{tag}</div>
      ))}
      </div>

      <h2>Drinks</h2>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr", gap: "12px", }}>

        {items.map((item) => {
          if (filters && item.tags.indexOf(filters) < 0) {
            return null;
          }
          return (

            <div style={{ 
              // width: "100%", 
              color: "white", 
              padding: "4px",  
              height: "120px", 
              backgroundSize: "contain",
              backgroundImage: "url(https://dishesdelish.com/wp-content/uploads/2017/05/Paradise-Cocktail-square-2.jpg.webp)"}} 
              onClick={() => setSelectedItem(item)}>
            {item.name}
          </div>
            )
        })}
      </div>

      {selectedItem && (
        <StoriesTest item={selectedItem} stories={selectedItem.stories} onAllStoriesEnd={() => setSelectedItem(null)} />
      )}
    </div>
  );
}

export default App;
